<template>
    <!-- 
        Ярлики відповідей, дозволяє -
        - переглянути список ярликів ( з пошуком по ярлику та повідомлення), та з фільтрацією по групас та співробітникам
        - відкрити картку ярлика
        - викликати створення ярлика
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога) та 1101 (створення ярлику) та перевірку хто переглядає картку співробітника
        - @create - подія, яка спрацьовує при створенні нового ярлику
        - @open - подія, яка спрацьовує при відкритті картки ярлику
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms['1101'] || this.perms['2000']" 
        @create="showModalAdd = true"
        @open="open"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
        Виклик вікна створення картки ярлику
        - для відкриття застосовується перевірка значення showModalAdd
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <createdbox 
        v-if="showModalAdd" 
        @close="showModalAdd = false"
    ></createdbox>

    <!-- 
        Виклик відкриття картки 
        - для відкриття застосовується перевірка значення showModal
        - @close - подія, яка спрацьовує при закритті картки
        - :objcard - параметр-об'єкт з даними по ярлику
    -->
    <viewcard 
        v-if="showModal == true" 
        @close="closeModal" 
        :objcard="objcard"
    ></viewcard>

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import infoblocks from '@/components/info/blocks' // інфо-блоки
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import createdbox from './create.vue' // картка створення
import viewcard from './view' // картка ярлику
import { Shortcuts } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { getNameGroup } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Shortcuts();

export default {
    data() {
        return {
            title: this.$t('answerLabels'), // Заголовок
            showModalAdd: false, // прапорець відображення вікна створення ярлика
            showModal: false, // прапорець відображення вікна картки
            objcard: {}, // Об'єкт з даними ярлика для відображення в модальному вікні
            infoblockShow: false, // прапорець відображення інфо-блоків
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "shortcuts"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                groupId: '',
                workerId: '',
            },
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "shortcutId",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("Label"),
                    text: "shortcutName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("Message"),
                    text: "message",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t('Group'),
                    text: "groupId",
                    align: "right",
                    status: true,
                    mutate: (item) => this.nameGroup(item)
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerName",
                    align: "right",
                    status: true
                },
            ],
            rows: [], // Дані для відображення у таблиці
        }
    },
    components: {
        PageHeader,
        createdbox,
        tablecustom,
        infoblocks,
        viewcard,
        // preloader
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(page){
            /**
                Отримує дані про ярлики з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getLabels(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #1100`);
                }
            })
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за ярликом та повідомленням
            */

            this.objParams.search = e
            this.getdata();
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModal в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.showModal = false;
            this.getdata(this.objParams.page);
        },
        open: function(e){
            /**
                Функція для відкриття модального вікна з інформацією про ярлик за його ідентифікатором.
                * @param {Object} e - об'єкт картки ярлику

                Викликає API для отримання інформації про ярлик.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */
            apiServe.getLabel(e.shortcutId).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        nameGroup(item){
            /*
                Функція для отримання назви групи
            */
            return getNameGroup(item)
        },
    },
    mounted() {
        // Cлухання події saveShortcuts для оновлення даних після збереження запису
        this.eventBus.off('saveShortcuts');
        this.eventBus.on('saveShortcuts', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        groups() {
            /*
                Повертає дані груп з глобального стору.
            */
            return storeS.groups
        }
    }
}
</script>